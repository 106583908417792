import { UiLoaderAnimationVanilla } from '@skillbox/front-box/dist/Loaders/UiLoaderAnimation/UiLoaderAnimationVanilla'

export const createAlchemereTemplate = (isDarkTheme) => {
  return (
    `<section class="form-popup" data-theme="light">
      <div class="form-popup__wrapper ui-container">
          <header class="form-popup__header">
            <img
              class="form-popup__logo"
              src="${isDarkTheme ? 'https://cdn.skillbox.pro/wbd-front/skillbox-static/skillbox-dark.svg' : 'https://cdn.skillbox.pro/wbd-front/skillbox-static/skillbox.svg'}"
              width="84"
              height="19"
              alt="Skillbox"
            >

            <button
              class="form-popup__close"
              type="button"
              aria-label="Закрыть попап"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="m17.93 19.5-6.41-6.2-6.42 6.2L4 18.43l6.42-6.18L4 6.06 5.1 5l6.42 6.19L17.93 5l1.1 1.06-6.41 6.19 6.41 6.18-1.1 1.07Z" fill="currentColor"/>
              </svg>
            </button>
          </header>

          <div class="form-popup__content"></div>
        </div>
      </div>
    </section>`
  )
};

export const createLoaderTemplate = () => UiLoaderAnimationVanilla({ classes: 'form-popup__loader' });

export const createThanksTemplate = ({ title, desc, gifts }, infoBlock, getReferralBlock) => (
  `<div class="form-popup__main-alchemere">
    <div class="form-popup__main ui-typography-group">
      <h3 class="form-popup__title h h--4">
        ${title}
      </h3>

      <p class="form-popup__text p p--1">
        ${desc}
      </p>

        <ul class="form-popup__gifts alchemere-gifts">
          ${gifts.map((gift) => (
            `<li class="alchemere-gifts__item">
              <img
                class="alchemere-gifts__icon"
                width="32"
                height="32"
                src="${gift.icon}"
                alt=""
              >

              <h4 class="alchemere-gifts__subtitle t t--3">
                ${gift.title}
              </h4>

              <p class="alchemere-gifts__text p p--3">
                ${gift.description}
              </p>
            </li>`
          )).join('')}
      </ul>

      ${infoBlock ? infoBlock.outerHTML : ''}
    </div>

    ${getReferralBlock ? getReferralBlock.outerHTML : ''}
  </div>`
);

export const createPollTemplate = (src, images) => {
  return `<div class="form-popup__survey">
      <picture class="form-popup__picture">
        <source
          media="(min-width: 768px)"
          srcset="${images.webp['1x']} 1x, ${images.webp['2x']} 2x"
          type="image/webp"
        >

        <img
          class="form-popup__img"
          src="${images.img['1x']}"
          srcset="${images.img['2x']} 2x"
          width="280"
          height="157"
          alt=""
        >
      </picture>

      <iframe
        class="form-popup__info"
        style="height: 0"
        src="${src}"
        frameborder="0"
      />
    </div>`
};

export const createErrorTemplate = (images) => {
  return (
    `<div class="form-popup__error">
      <picture class="form-popup__picture">
        <source
          media="(min-width: 768px)"
          srcset="${images.webp['1x']} 1x, ${images.webp['2x']} 2x"
          type="image/webp"
        >

        <img
          class="form-popup__img"
          src="${images.img['1x']}"
          srcset="${images.img['2x']} 2x"
          width="280"
          height="157"
          alt=""
        >
      </picture>
      <div class="form-popup__info">
        Похоже, произошла ошибка.<br>
        Попробуйте отправить снова или перезагрузите страницу.
      </div>
    </div>`
  )
};
