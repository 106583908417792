import { closePopupGtmTag } from '../libs/gtm';
import { activateFocusTrap, deactivateFocusTrap } from '../utils/focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { showAutopaymentModal } from '../../components/popups/v1/AutopayModal/scripts';
import { showUpSaleModal } from '../../components/popups/v1/UpSaleModal/scripts'
import { showTariffsModal } from '../../components/popups/v1/TariffsModal/scripts';

window.activePopups = [];

const closePopup = () => {
  if (window.activePopups.length === 0) {
    return;
  }

  const {popup, callback} = window.activePopups.pop();

  callback && callback();
  deactivateFocusTrap(popup);
  popup.classList.remove('js-popup--active');
  enableBodyScroll(popup);

  if (window.activePopups.length === 0) {
    document.removeEventListener('keydown', escPressHandler);
    document.body.classList.remove('ios-safari-overflow'); // fix for iOS
  }
};

const checkClosePopupEvent = (evt) => {
  const target = evt.target;
  const parent = target.closest('.js-popup__close, .js-popup');
  const isButtonClose = parent ? Boolean(parent.closest('.js-popup__close')) : false;

  return target === parent || isButtonClose;
};

const closePopupEvent = (evt) => {
  if (checkClosePopupEvent(evt)) {
    closePopup();
    closePopupGtmTag();
  }
};

const escPressHandler = (evt) => {
  if (evt.key === 'Escape' || evt.key === 'Esc') {
    closePopup();
  }
};

const showPopup = (popup, callback) => {
  window.activePopups.push({popup, callback});

  // fix for iOS
  document.querySelector('body').classList.add('ios-safari-overflow');
  disableBodyScroll(popup, {
    allowTouchMove: el => el.closest('.iti__country-list')
  });

  popup.classList.add('js-popup--active');

  activateFocusTrap(popup);
  popup.focus();

  popup.addEventListener('click', closePopupEvent);
  document.addEventListener('keydown', escPressHandler);
};

const showAutopaymentPopup = ({ type, blockName, callback }) => {
  const template = document.querySelector('#auto-payment-form');
  const autopaymentModal = template?.content.cloneNode(true).querySelector('.auto-payment-form');

  if (!autopaymentModal) {
    window.Sentry.captureException('Autopayment popup is required');
    return
  }

  const tariffsModal = document.querySelector('#tariffs-v4-modal')?.content.querySelector('.tariffs-v4-modal__wrapper')

  if (tariffsModal) {
    showTariffsModal(tariffsModal, { type, blockName })

    return
  }

  const upSaleTemplate = document.querySelector('#up-sale-form')?.content.cloneNode(true).querySelector('.up-sale-form')

  if (upSaleTemplate) {
    showUpSaleModal(upSaleTemplate, {
      submitHandler: (nomenclatureId) => showAutopaymentModal(autopaymentModal, { nomenclatureId, blockName }),
      type,
      blockName
    })

    return
  }

  // доработка в рамках задачи WEBDEV-171419
  if (typeof enabledPaymentModal !== 'undefined' && enabledPaymentModal) {  // eslint-disable-line
    showAutopaymentModal(autopaymentModal, {
      nomenclatureId: landingInfo.nomenclature_ID, // eslint-disable-line
      type,
      blockName
    })

    return
  }

  if (type !== 'pay') {
    callback?.()
    return
  }

  showAutopaymentModal(autopaymentModal, {
    nomenclatureId: landingInfo.nomenclature_ID, // eslint-disable-line
    type,
    blockName
  })
};

export { closePopup, checkClosePopupEvent, showPopup, showAutopaymentPopup };
