export class Spoiler {
  constructor(el) {
    this.details = el;
    this.summary = el.querySelector('.ui-spoiler__summary');
    this.content = el.querySelector('.ui-spoiler__content');

    if (!this.content) {
      return;
    }

    this.animation = null;
    this.isClosing = false;
    this.isExpanding = false;
    this.isDetailsTag = !!this.details.closest('details') // Для additional-item, там не <details>, а <div>

    this.summary.addEventListener('click', (evt) => this._clickHandler(evt));

  }

  _clickHandler(evt) {
    evt.preventDefault();

    const isSupportingAnimate = typeof (this.details.animate) === 'function' // fix safari 1-13 animate

    this.details.style.overflow = 'hidden';

    const isOpen = this.details.open || this.details.dataset.open === 'true'

    if (this.isClosing || !isOpen) {
      if (isSupportingAnimate) {
        this._open()
      } else {
        this.isDetailsTag
            ? this.details.open = true
            : this.details.dataset.open = true
      }

      this._pushAnalytics();
    } else if (this.isExpanding || isOpen) {
      if (isSupportingAnimate) {
        this._shrink()
      } else {
        this.isDetailsTag
            ? this.details.open = false
            : this.details.dataset.open = false
      }
    }
  }

  _shrink() {
    this.isClosing = true;

    const startHeight = `${this.details.offsetHeight}px`;
    const endHeight = `${this.summary.offsetHeight}px`;

    if (this.animation) {
      this.animation.cancel();
    }

    this.animation = this.details.animate({
      height: [startHeight, endHeight]
    }, {
      duration: 400,
      easing: 'ease-out'
    });

    this.content.animate({
      opacity: [1, 0]
    }, {
      duration: 200,
      easing: 'ease-in-out',
      fill: 'forwards'
    })

    this.animation.onfinish = () => this._finishAnimation(false);
    this.animation.oncancel = () => (this.isClosing = false);
  }

  _open() {
    this.details.style.height = `${this.details.offsetHeight}px`;

    this.isDetailsTag
        ? this.details.open = true
        : this.details.dataset.open = 'true'

    window.requestAnimationFrame(() => this._expand());
  }

  _expand() {
    this.isExpanding = true;

    const startHeight = `${this.details.offsetHeight}px`;
    const endHeight = `${this.summary.offsetHeight + this.content.offsetHeight}px`;

    if (this.animation) {
      this.animation.cancel();
    }

    this.animation = this.details.animate({
      height: [startHeight, endHeight]
    }, {
      duration: 400,
      easing: 'ease-out'
    });

    this.content.animate({
      opacity: [0, 1]
    }, {
      duration: 200,
      easing: 'ease-in-out',
      fill: 'forwards'
    });

    this.animation.onfinish = () => this._finishAnimation(true);
    this.animation.oncancel = () => (this.isExpanding = false);
  }

  _finishAnimation(open) {
    this.isDetailsTag
        ? this.details.open = open
        : this.details.dataset.open = open

    this.animation = null;
    this.isClosing = false;
    this.isExpanding = false;

    this.details.removeAttribute('style');
  }

  _pushAnalytics() {
    const spoilersGtmName = this.details.closest('[data-gtm]');

    if (spoilersGtmName) {
      const gtmAttribute = spoilersGtmName.dataset.gtm;
      const itemText = this.summary.innerText;

      const eventName = `${gtmAttribute}-accordion-click`;
      window.$analytics && window.$analytics.push(eventName, { text: itemText });
    }
  }
}

export const spoilerInit = () => {
  const spoilers = document.querySelectorAll('.ui-spoiler');

  spoilers.forEach(spoiler => new Spoiler(spoiler));
};
