import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { render, createElement } from '../../utils/render';
import AbstractView from '../../utils/abstract-view';
import { getExtraParams } from '../../utils/utm';
import { setCookie } from '../../utils/cookies';
import { setTooltipHandler } from '../tooltip';

import {
  createAlchemereTemplate,
  createPollTemplate,
  createLoaderTemplate,
  createThanksTemplate,
  createErrorTemplate
} from './alchemere.template';
import { content } from './data';
import { Media } from '../../utils/const'

const TIMEOUT_LIMIT = 5000;

export class Alchemere extends AbstractView {
  constructor(url) {
    super();

    this._root = document.body;
    this._data = window.$paymentData;
    this._content = content;
    this._url = url;
    this._timeout = null;
    this._referralObserver = null;

    this._clickCloseHandler = this._clickCloseHandler.bind(this);
    this._escPressHandler = this._escPressHandler.bind(this);
    this._clickSocialHandler = this._clickSocialHandler.bind(this);
    this._messageAlchemereHandler = this._messageAlchemereHandler.bind(this);

    render(this._root, this.getElement());
    disableBodyScroll(this.getElement());
    document.body.classList.add('ios-safari-overflow'); // fix for iOS

    this._contentWrapper = this.getElement().querySelector('.form-popup__content');
    this._loaderElement = null;
    this._thanksElement = null;
    this._surveyElement = null;
    this._errorElement = null;

    window.$analytics?.push('thankyou-viewed', { label: 'form_i' });

    this.init();
  }

  init() {
    this._startAwaitAlchemere();

    this._loaderElement = createElement(createLoaderTemplate());
    this._surveyElement = createElement(createPollTemplate(this.src, this._content.images));

    render(this._contentWrapper, this._loaderElement);
    render(this._contentWrapper, this._surveyElement);
  }

  getTemplate() {
    const isDarkTheme = false;
    return createAlchemereTemplate(isDarkTheme);
  }

  async getInfoBlock() {
    const template = document.querySelector('#alchemere-info');

    if (this._content.hasInfo && template) {
      const infoElement = template.content.querySelector('.form-popup__socials');
      const links = infoElement.querySelectorAll('.form-popup__social a');
      const params = getExtraParams();

      if (params) {
        const { phone, email } = this._data;
        const skbClientId = window.clientIds.getSkbId();
        const gaClientId = await window.clientIds.getGaId();
        const sessionExternalId = skbClientId?.replace(/-/g, '');

        for (let link of links) {
          const union = new URL(link.href).search ? '&' : '?';
          link.href = `${link.href}${union}${params}&phone=${phone}&email=${email}&skb_client_id=${skbClientId}&ga_client_id=${gaClientId}&session_external_id=${sessionExternalId}`;
        }
      }

      return infoElement;
    }

    return null;
  }

  getReferralBlock() {
    const template = document.querySelector('#alchemere-referral');

    if (!template) {
      return null
    }

    return template.content.cloneNode(true).querySelector('.alchemere-referral');
  }

  setListenersToReferral(block) {
    const referral = block.querySelector('.alchemere-referral');

    if (!referral) {
      return
    }

    const lg = window.matchMedia(`(min-width: ${Media.LG}px)`);

    const tooltip = referral.querySelector('.alchemere-referral__tooltip');
    const button = referral.querySelector('.alchemere-referral__button');

    setTooltipHandler(tooltip);

    this._referralObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        window.$analytics?.push('referral-viewed');
        this._disconnectObserver();
      }
    });

    lg?.matches && tooltip?.addEventListener('mouseenter', () => window.$analytics?.push('referral-prompt-click'));
    tooltip?.addEventListener('click', () => window.$analytics?.push('referral-prompt-click'));
    button?.addEventListener('click', () => window.$analytics?.push('referral-button-click'));

    setTimeout(() => {
      this._referralObserver.observe(referral);
    }, 250);
  }

  _disconnectObserver() {
    if (this._referralObserver) {
      this._referralObserver.disconnect();
      this._referralObserver = null;
    }
  }

  get src() {
    const { email, phone, requestId } = this._data;

    return `${this._url}?email=${email}&phone=${phone}&request_id=${requestId}`;
  }

  _clickSocialHandler(evt) {
    const target = evt.target.closest('.form-popup__social a')

    if (target) {
      window.$analytics?.push('alchemere-social-click', {
        label: target.textContent.replace(/\s/g, '')
      });
    }
  }

  _messageAlchemereHandler(evt) {
    const GAP = 120;
    const isAlchemere = evt.origin === 'https://survey.alchemer.eu';

    if (isAlchemere) {
      const isSetHeightEvent = evt.data?.event === 'setHeight';
      const isEndSurveyEvent = evt.data?.event === 'endSurvey';

      this._timeout && this._stopAwaitAlchemere();

      if (isSetHeightEvent) {
        this._surveyElement.querySelector('iframe').style.height = `${evt.data.height + GAP}px`;
        this._loaderElement?.remove();
        this.getElement().scrollTo(0, 0);
      }

      if (isEndSurveyEvent) {
        const WEEK = 7 * 24 * 60 * 60;

        setCookie('surveyAlchemereWasPassedV2', true, {
          'max-age': WEEK,
          domain: `.${window.location.hostname}`
        });

        this._changeViewPage();
      }
    }
  }

  _startAwaitAlchemere() {
    this._timeout = setTimeout(() => {
      this._loaderElement?.remove();
      this._surveyElement?.remove();

      this._errorElement = createElement(createErrorTemplate(this._content.images));

      render(this._contentWrapper, this._errorElement);

      window.Sentry?.captureException(`[Alchemere popup]: Не удалось получить доступ к анкете за ${TIMEOUT_LIMIT}мс.`,
        { extra: { url: this.src } })
      this._stopAwaitAlchemere()
    }, TIMEOUT_LIMIT)
  }

  _stopAwaitAlchemere() {
    clearTimeout(this._timeout)
    this._timeout = null
  }

  async _changeViewPage() {
    this._contentWrapper.innerHTML = '';
    this._contentWrapper.classList.remove('form-popup__content--question');
    this._thanksElement = createElement(createThanksTemplate(this._content, await this.getInfoBlock(), this.getReferralBlock()));

    this.setListenersToReferral(this._thanksElement)

    render(this._contentWrapper, this._thanksElement);

    window.$analytics?.push('thankyou-closed', { label: 'form_i' })
    window.$analytics?.push('thankyou-viewed', { label: 'End' });
  }

  _closeAlhemerePopup() {
    enableBodyScroll(this.getElement());
    document.body.classList.remove('ios-safari-overflow'); // fix for iOS

    window.$analytics?.push('thankyou-closed', { label: this._thanksElement ? 'End' : 'form_i' })

    this.unrender();
  }

  _clickCloseHandler() {
    this._closeAlhemerePopup();
  }

  _escPressHandler(evt) {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      this._closeAlhemerePopup();
    }
  }

  bind() {
    window.addEventListener('message', this._messageAlchemereHandler, false);
    this.getElement().querySelector('.form-popup__close').addEventListener('click', this._clickCloseHandler);
    document.addEventListener('keydown', this._escPressHandler);
    this.getElement().addEventListener('click', this._clickSocialHandler);
  }

  unbind() {
    window.removeEventListener('message', this._messageAlchemereHandler, false);
    this.getElement().querySelector('.form-popup__close').removeEventListener('click', this._clickCloseHandler);
    document.removeEventListener('keydown', this._escPressHandler);
    this.getElement().removeEventListener('click', this._clickSocialHandler);
    this._disconnectObserver();
  }
}
