import { UiTagVanilla } from '@skillbox/front-box/dist/VisualElements/UiTag/UiTagVanilla'
import { createElement, render, RenderPosition } from '../../../../js/utils/render'
import { Spoiler } from '../../../../js/components/Spoiler'
import Form from '../../../../js/Form/Form'

const getPaymentMethodTemplate = (data) => {
  const { title, description, tag, value, analytics, paymentType, paymentPrice, details, images } = data

  return (
    `<div class="auto-payment-form__type extended-autopayment-method" id="${value}">
      <label class="extended-autopayment-method__label">
        <input
          name="payment_type"
          value="${value}"
          type="radio"
          data-analytics="${analytics}"
          data-payment-type="${paymentType}"
          data-payment-price="${paymentPrice}"
          class="extended-autopayment-method__input sr-only"
        >

        <span class="extended-autopayment-method__box">
          <span class="extended-autopayment-method__header">
            <strong class="extended-autopayment-method__title t t--2">
              ${title}
            </strong>

            ${tag
              ? UiTagVanilla({
                  data: {
                    title: tag.title,
                    background_color: 'var(--accent-sale-color)',
                    color: 'var(--accent-always-black-color)'
                  },
                  classes: 'extended-autopayment-method__tag'
                })
              : ''
            }
          </span>

          <span
            class="extended-autopayment-method__details"
            style="height: 0;"
          >
            <span class="extended-autopayment-method__desc p p--2">
              ${description}
            </span>

            ${details?.length
              ? `<span class="extended-autopayment-method__list f">
                  ${details.map(({ title, description }, i) => {
                    const isTotal = value !== 'cloudpayments' && i === details.length - 1

                    return (
                      `<span class="extended-autopayment-method__item ${isTotal ? 'extended-autopayment-method__item--total f f--16 f--m' : 'p p--2'}">
                        <span class="extended-autopayment-method__item-text">
                          ${title}
                        </span>

                        <span class="extended-autopayment-method__item-price">
                          ${description}
                        </span>
                      </span>
                    `)
                  }).join('')}
                </span>`
              : ''
            }
          </span>

          ${images?.length
            ? `<span class="extended-autopayment-method__images">
                ${images.map(({ src, alt }) => (`
                  <img
                    src="${src}"
                    alt="${alt}"
                    height="16"
                  >
                `)).join('')}
              </span>`
            : ''
          }
        </span>
      </label>
    </div>`
  )
}

const getHeaderTemplate = ({ title, description }) => (
  `<div class="auto-payment-form__header ui-typography-group">
    <h3 class="auto-payment-form__title h h--5">
      ${title}
    </h3>

    ${description
    ? `<p class="auto-payment-form__desc p p--2">
          ${description}
        </p>`
    : ''
  }
  </div>`
)

export class AutoPaymentForm {
  data = {
    pay: {
      title: 'Выберите способ оплаты'
    },
    consult: {
      title: 'Мы скоро позвоним!',
      description: 'А&nbsp;начать учиться вы&nbsp;можете уже сейчас&nbsp;&mdash; при полной оплате картой на&nbsp;сайте дарим дополнительную скидку'
    }
  }

  constructor({ wrapper, template, nomenclatureId, methods, type = 'pay', blockName }) {
    this.wrapper = wrapper
    this.template = template
    this.methods = methods
    this.type = type

    this.methodsWrapper = template.querySelector('.auto-payment-form__types')
    this.form = template.querySelector('.form')
    this.form.nomenclature_id.value = nomenclatureId
    this.buttonContent = this.form.querySelector('.auto-payment-form__button .ui-button__text')

    this.headerTemplate = null
    this.selectPaymentType = null
    this.selectMethod = null
    this.blockTriggerName = blockName
  }

  render() {
    if (!this.methods || this.methods.length === 0) {
      const error = new Error(`[AutoPaymentForm]: methods is required - ${this.methods}`)
      window.Sentry?.captureException(error)
      return
    }

    this.renderHeaderElement()
    this.renderPaymentMethods()

    this.selectPaymentType = this.form.querySelector('.extended-autopayment-method__input')
    this.selectPaymentType.checked = true
    this.selectMethod = this.selectPaymentType.closest('.extended-autopayment-method')

    this.accordionInit()
    this.formInit()

    this.setButtonContent()

    this.form.addEventListener('change', this.formChangeHandler)
    this.form.addEventListener('form-sended', this.formSubmitHandler)

    render(this.wrapper, this.template)

    this.setDetailsHeight()
  }

  destroy() {
    this.form.removeEventListener('change', this.formChangeHandler)
    this.form.removeEventListener('form-sended', this.formSubmitHandler)

    this.removeHeaderElement()
    this.removePaymentMethods()
    this.template.remove()
  }

  renderPaymentMethods() {
    const fragment = document.createDocumentFragment()

    this.methods.forEach((method) => {
      const paymentMethodElement = createElement(getPaymentMethodTemplate(method))
      render(fragment, paymentMethodElement)
    })

    render(this.methodsWrapper, fragment)
  }

  removePaymentMethods() {
    this.methodsWrapper.innerHTML = ''
  }

  accordionInit() {
    const detail = this.template.querySelector('[data-accordion]')
    const spoilers = detail.querySelectorAll('.ui-spoiler')

    detail.addEventListener('click', evt => {
      const target = evt.target.closest('summary')

      if (target) {
        const parent = target.parentElement

        if (!parent.open) {
          const gtmAttribute = detail.dataset.gtm
          const itemText = target.innerText

          if (gtmAttribute && itemText) {
            const eventName = `${gtmAttribute}-accordion-click`
            window.$analytics?.push(eventName, { text: itemText })
          }
        }
      }
    })

    spoilers.forEach(spoiler => new Spoiler(spoiler))
  }

  formInit() {
    const autopayment = this.methods.find(method => method.autopayment)?.autopayment || {}
    const paymentForm = new Form({ form: this.form, autopaymentData: autopayment })
    paymentForm.init()

    paymentForm.onCloudPayment = () => {
      this.emitFormEvent(this.form, { formData: new FormData(this.form) }, 'form-success')
    }
  }

  setButtonContent() {
    this.buttonContent.innerHTML = `
      <span class="f f--16 f--m">${this.selectPaymentType.dataset.paymentType}</span>
      <span class="f f--12">${this.selectPaymentType.dataset.paymentPrice}</span>`
  }

  setDetailsHeight(height = null) {
    const details = this.selectMethod.querySelector('.extended-autopayment-method__details')
    details.style.height = height !== null ? height : `${details.scrollHeight}px`
  }

  emitFormEvent(form, params, name) {
    const customEvent = new Event(name)

    customEvent.sendingResult = params
    form.dispatchEvent(customEvent)
  }

  formChangeHandler = (evt) => {
    this.setDetailsHeight(0)

    this.selectPaymentType = evt.target
    this.selectMethod = evt.target.closest('.extended-autopayment-method')

    this.setDetailsHeight()
    this.setButtonContent()

    window.$analytics?.push('autopayment-modal-type-change', {
      label: `${this.blockTriggerName} | ${evt.target.dataset.analytics}`,
      blockName: this.blockTriggerName
    })
  }

  formSubmitHandler = (evt) => {
    window.$analytics?.push('autopayment-modal-type-submit', {
      requestId: evt.sendingResult?.formData.get('request_id'),
      label: `${this.blockTriggerName} | ${this.selectPaymentType?.dataset.analytics}`,
      blockName: this.blockTriggerName
    })
  }

  renderHeaderElement() {
    this.headerTemplate = createElement(getHeaderTemplate(this.data[this.type]))
    render(this.template, this.headerTemplate, RenderPosition.AFTERBEGIN)
  }

  removeHeaderElement() {
    if (this.headerTemplate === null) {
      return
    }

    this.headerTemplate.remove()
    this.headerTemplate = null
  }
}
